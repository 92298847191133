export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const sideBarAdmin = [
    {path: '/dashboard', icon: 'fas fa-tachometer-alt', title: 'Escritorio'},
    {path: '/users', title: 'Usuarios', icon: 'fas fa-users', children: []},
    {path: '/admins', title: 'Admins', icon: 'fas fa-users-cog', children: []},
    {path: '/therapist', title: 'Terapeutas', icon: 'fas fa-user-md', children: []},
    {path: '/bookings', title: 'Citas', icon: 'fas fa-address-book', children: []},
    {path: '/bookings-gnp', title: 'Citas gnp', icon: 'fas fa-address-book', children: []},
    //{path: '/income', title: 'Booking Income', icon: 'fas fa-book-reader', children: []},
    {path: '/reportes', title: 'Reporte de hrs', icon: 'fas fa-business-time', children: []},
    {path: '/categories', title: 'Categorias', icon: 'fas fa-tasks', children: []},
    {path: '/promo-codes', title: 'Cupones', icon: 'fas fa-percentage', children: []},
    {path: '/plans', title: 'Planes', icon: 'fas fa-id-card', children: []},
    {path: '/subscriptions', title: 'Suscripciones', icon: 'fas fa-book', children: []},
    {path: '/companies', title: 'Socios', icon: 'fas fa-building', children: []},
];

export const userRoles = {
    admin:'admin_1', patient: 'patient', doctor:'doctor'
}

export const appointmentsStatus = {
    scheduled: 'scheduled_appointment', finished: 'appointment_finished', 
    to_be_confirmed: 'to_be_confirmed_by_therapist', rejected: 'appointment_rejected',
    payment_rejected: 'payment_rejected', one_connected: 'one_connected',
    appointment_in_progress: 'appointment_in_progress', appointment_moved: 'appointment_moved',
    appointment_cancelled: 'appointment_cancelled'
}

export const usersStatus = {
    activated: 'activated', blocked:'blocked'
}
