import {Inject, Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import * as constant from './constants';
import {apiUrl} from './apiUrls';
import {ToastrService} from 'ngx-toastr';
import {Lightbox} from 'ngx-lightbox';
import {BehaviorSubject} from 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import * as _ from 'lodash';
import {DOCUMENT} from '@angular/common';
import {ExportToExcelService} from './exportToExcel.service';
import * as moment from 'moment';

import Swal from 'sweetalert2';

import * as CryptoJS from 'crypto-js';


@Injectable()
export class ApiService {

    constant = constant;
    apiUrl = apiUrl;
    noImage = '/assets/images/noImage.png';
    userPlaceholder = '/assets/images/ic_user.png';
    placeholder = '/assets/images/placeholder.jpg';
    gif = '/assets/images/gif.gif';
    plusIcon = 'assets/images/plus_icon.png';
    modalRef: BsModalRef;

    public readonly apiEndpoint: String;
    public apignp: String = 'https://apignp.mindhelp.mx/api/v1/'
    private loaderSubject = new BehaviorSubject<any>(null);
    public loaderStatus = this.loaderSubject.asObservable();
    public heading: string;
    domain: string;

    constructor(
        private router: Router,
        private http: HttpClient,
        public toastr: ToastrService,
        public lightBox: Lightbox,
        public modalService: BsModalService,
        @Inject(DOCUMENT) public document: any,
        public exportService: ExportToExcelService
    ) {
        this.apiEndpoint = environment.apiBaseUrl;
        this.domain = this.document.location.origin;
    }

    setTouched(form) {
        Object.keys(form.controls).forEach(key => {
            form.controls[key].markAsTouched({onlySelf: true});
        });
    }

    sweetConfirm(msg) {
        let flag = false;
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want te delete this ' + msg,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            // return !!result.value;
            flag = true;
        });
        return flag;
    }

    navigate(url, params) {
        if (params) {
            this.router.navigate([`/${url}`, params]);
        } else {
            this.router.navigate([`/${url}`]);
        }
    }

    changeTitle(title) {
        this.heading = title;
    }

    loaderOn(loaderStatus) {
        this.loaderSubject.next(loaderStatus);
    }

    getData(url, obj, isLoading, mode:number = 1) {
        let params = new HttpParams();
        Object.keys(obj).forEach(key => {
            console.log(obj)
            if (obj[key] !== '' && obj[key] !== undefined) {
                params = params.set(key, obj[key]);
            }
        });
        if(mode === 1) return this.http.get<any>(this.apiEndpoint + url, {params: params, reportProgress: isLoading});
        else return this.http.get<any>(this.apignp + url, {params: params, reportProgress: isLoading});
       
    }

    getDataGNP(url, obj, isLoading) {
        let params = new HttpParams();
        Object.keys(obj).forEach(key => {
            if (obj[key] !== '' && obj[key] !== undefined) {
                params = params.set(key, obj[key]);
            }
        });
        return this.http.get<any>('https://apignp.mindhelp.mx/api/v1/' + url, {params: params, reportProgress: isLoading});
    }

    /*getDataEnc(url, obj, isLoading) {
        var data = {message: this.encrypt(obj, key)};
        return this.http.get<any>(this.apiEndpoint + url, {params: params, reportProgress: isLoading});
    }*/

    postData(url, obj, isLoading, byForm=false) {
        const formData = new FormData();
        Object.keys(obj).forEach(key => {
            if (obj[key] !== '' && obj[key] !== undefined) {
                formData.append(key, obj[key]);
            }
        });
        return this.http.post<any>(this.apiEndpoint + url, (byForm ? formData : obj), {reportProgress: isLoading});
    }

    postDataEnc(url, obj, key = '', isLoading= false){
        var data = {message: this.encrypt(obj, key)};
        return this.http.post<any>(this.apiEndpoint + url, data, {reportProgress: isLoading});
    }

    putData(url, obj, isLoading) {
        const formData = new FormData();
        Object.keys(obj).forEach(key => {
            if (obj[key] !== '' && obj[key] !== undefined) {
                formData.append(key, obj[key]);
            }
        });
        return this.http.put<any>(this.apiEndpoint + url, formData, {reportProgress: true});
    }

    patchData(url, obj, isLoading) {
        const formData = new FormData();
        Object.keys(obj).forEach(key => {
            if (obj[key] !== '' && obj[key] !== undefined) {
                formData.append(key, obj[key]);
            }
        });
        return this.http.patch<any>(this.apiEndpoint + url, formData, {reportProgress: true});
    }

    patchDataEnc(url, obj, key = '', isLoading= false){
        var data = {message: this.encrypt(obj, key)};
        return this.http.patch<any>(this.apiEndpoint + url, data, {reportProgress: isLoading});
    }

    deleteData(url) {
        return this.http.delete<any>(this.apiEndpoint + url, {reportProgress:true});
    }

    downloadLink(url) {
        window.location.href = url;
    }

    openLightBox(data) {
        if (data) {
            const temp: any = [];
            temp.push({
                src: data,
                thumb: data
            });
            const options = {
                positionFromTop: 60
            };
            this.lightBox.open(temp, 0, options);
            return true;
        }
    }

    uploadImage(url, file) {
        const formData = new FormData();
        formData.append('image', file);
        return this.http.post<any>(this.apiEndpoint + url, formData, {reportProgress: true});
    }

    checkImage(file) {
        if (file) {
            if (file.size < 5000000) {
                if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                    return true;
                } else {
                    this.toastr.error('Please add jpg or png image only');
                }
            } else {
                this.toastr.error('Please add image less than 5 MB');
            }
        }
        return false;
    }

    noError(res) {
        if (res.status === 400) {
            this.toastr.error(res.msg);
            return false;
        } else {
            return true;
        }
    }

    showModal(template, size) {
        if (size) {
            this.modalRef = this.modalService.show(template,
                {
                    backdrop: 'static',
                    keyboard: false,
                    class: `gray modal-${size}`
                }
            );
        } else {
            this.modalRef = this.modalService.show(template,
                {
                    backdrop: 'static',
                    keyboard: false
                }
            );
        }
    }

    hideModal() {
        this.modalRef.hide();
    }

    blocked() {
        this.toastr.success('Bloqueado correctamente');
    }

    unblocked() {
        this.toastr.success('Desbloqueado correctamente');
    }

    updated() {
        this.toastr.success('Actualizado correctamente');
    }

    added() {
        this.toastr.success('Agregado correctamente');
    }

    deleted() {
        this.toastr.success('Borrado correctamente');
    }

    select(value) {
        this.toastr.error(`Por favor selecciona ${value}`);
    }

    printExcel(res, fileName, type) {
        const arr: any = [];
        let tempArr: any = [];
        /*if (flag == 1) {
            //tempArr = res.doctorlist;
            tempArr = res.data.items;
        } else {
            //tempArr = res.userlist;
            tempArr = res.data.items;
        }*/
        tempArr = res.data.items;

        tempArr.forEach((val, key) => {
            /*let isPush = false;
            val.roles.forEach(role => {
                if(role.name == type){
                    isPush = true;
                }
            });
            if(isPush)*/
            if(type == constant.userRoles.patient){
                arr.push({
                    'No': key + 1,
                    'Nombre': val.first_name + ' ' + val.last_name,
                    'Correo': val.email || '',
                    'Teléfono': val.country_code + ' ' + val.phone || '',
                    'Miembro desde ': (val.working_since? moment(val.working_since).format('DD-MM-YYYY') : ''),
                    'No. Sesiones': val.appointment_as_user ? val.appointment_as_user.length || '0':'0',
                    'Socio': val.company_id ? val.company_id.name || '': '',
                    'Estatus': val.account_status || ''
                });
            }
            if(type == constant.userRoles.admin){
                arr.push({
                    'No': key + 1,
                    'Nombre': val.first_name + ' ' + val.last_name,
                    'Correo': val.email || '',
                    'Socio': val.company_id ? val.company_id.name || '': '',
                    'Estatus': val.account_status || ''
                });
            }
            if(type == constant.userRoles.doctor){
                arr.push({
                    'No': key + 1,
                    'Nombre': val.first_name + ' ' + val.last_name,
                    'Correo': val.email || '',
                    'Teléfono': val.country_code + ' ' + val.phone || '',
                    'Miembro desde ': (val.working_since? moment(val.working_since).format('DD-MM-YYYY') : ''),
                    'No. Sesiones': val.appointment_as_user ? val.appointment_as_user.length || '0': '0',
                    'EStatus': val.account_status || ''
                });
            }
        });
        this.exportService.exportAsExcelFile(arr, fileName);
    }

    changeDate(val) {
        let date = new Date();
        let splitDate = val.split('-');
        date.setFullYear(parseInt(splitDate[2]));
        date.setMonth(parseInt(splitDate[1]) - 1);
        date.setDate(parseInt(splitDate[0]));
        return date;
    }

    private publicKey = "OnnptHpzXXyREqAbmRYx0RCgp1vMRoHA";
    private secureIV = "";
    private privateKey = "";

    encrypt(data: any, keyP: string = ''){
        //this.privateKey = keyP;
        let key = (keyP == '') ? this.publicKey : localStorage.getItem('ppk');//this.privateKey;

        if(key.length != 32){return '';}

        this.secureIV = key.substr(0,16);

        let _key = CryptoJS.enc.Utf8.parse(key);
        let _iv = CryptoJS.enc.Utf8.parse(this.secureIV);

        let encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), _key, 
            {keySize:128, iv:_iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7});
        //let dataDes = this.decrypt(encrypted.toString(),key);
        return encrypted.toString();
    }

    decrypt(data:any, keyP:string = ''){
        //this.privateKey = keyP;
        let key = (keyP == '') ? this.publicKey : localStorage.getItem('ppk');//this.privateKey;

        if(key.length != 32){return '';}

        this.secureIV = key.substr(0,16);

        let _key = CryptoJS.enc.Utf8.parse(key);
        let _iv = CryptoJS.enc.Utf8.parse(this.secureIV);

        let decrypted = CryptoJS.AES.decrypt(data, _key, 
            {keySize:128, iv:_iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7}).toString(CryptoJS.enc.Utf8);
        return decrypted;//.toString();
    }

    getReports(status) {
        let url = `${this.apiEndpoint}ecomm/users/get-all-reports-by-doctor?status=${status}`;
        return this.http.get(url);
    }

    updateReport(id,status,notes,key=""){
        let url = `${this.apiEndpoint}ecomm/users/update-bill-report?appointmentToBillReportId=${id}&status=${status}&notes=${notes}`;
        var dataEnc = {message: this.encrypt({}, key)};
        return this.http.put(url,dataEnc);
    }

    sendBill(reportId, file) {
        let url = `${this.apiEndpoint}ecomm/users/upload-bill-report-media?appointmentToBillReportId=${reportId}&type=voucher`;
        const formData = new FormData();
        formData.append('media_source', file);
        return this.http.put(url, formData);
    }

    getCharts(params, mode:number) {
        let url = ''
        if(mode === 1) url = `${this.apiEndpoint}admin/appointments/get-appointments-month?${params}`;
        else{ 
            params = params.replace('company_id=0','company_id=1')
            console.log('params reemplazados', params)
            url = `${this.apignp}admin/appointments/get-appointments-month?${params}`;
        }
        return this.http.get(url);
    }

}

