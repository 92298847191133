export const apiUrl = {

    //Old
    /*login: 'admin/login',
    dashboard: 'admin/dashboard-data',
    users: 'admin/users-list',
    therapist: 'admin/doctor-list',
    bookings: 'admin/booking-list',
    bookingIncome: 'admin/earning-list',
    promoCodes: 'admin/promocode-list',
    blockUnblock: 'admin/user-block-unblock',*/
    login: 'auth/local/signin',
    dashboard: 'admin/dashboard-data',
    admins: 'admin/users/admins',
    companies: 'admin/companies',
    users: 'admin/users/clients',
    therapist: 'admin/users/doctors',
    bookings: 'appointments',
    bookingIncome: 'appointments',
    bookingsMonth: 'admin/appointments-month',
    promoCodes: 'system/promo-codes',
    blockUnblock: 'users',
    plans: 'system/payments/plans',
    suscriptions: 'system/payments/suscriptions',
    promoCodeStats:'system/promo-codes/stats',

    addAdmin: 'auth/local/signup/admin',
    addTherapist: 'auth/local/signup/doctor',

    changePassword: 'auth/reset-password',
    requestChangePassword: 'auth/reset-password/request',
    categories: 'system/categories',
    updateUser: 'users',
    updateSubCategory: 'system/services',
    EditCategory: 'system/categories',
    updatePromo: 'system/promo-codes',
    deleteSubCategory: 'system/services',
    deletePromo: 'system/promo-codes',
    blockPromo: 'admin/active-block-promocode',
    deleteUser: 'users',

    updateStatusAppointment: 'admin/appointments/status'

    /*
    changePassword: 'admin/change-password',
    categories: 'admin/category-list',
    updateUser: 'admin/update-user-data',
    updateSubCategory: 'admin/update-subcategory',
    EditCategory: 'admin/edit-category',
    updatePromo: 'admin/update-promocode',
    deleteSubCategory: 'admin/del-subcategory',
    deletePromo: 'admin/del-promocode',
    blockPromo: 'admin/active-block-promocode',
    deleteUser: 'admin/user-delete'
    */

};

