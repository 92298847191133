import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './external/login/login.component';
import {PrivacyComponent} from './external/privacy/privacy.component';
import {ExternalAuthguardService} from './services/externalAuthguard.service';
import {ForgotPasswordComponent} from './external/forgot-password/forgot-password.component';

const routes: Routes = [
    {path: '', redirectTo: '/login', pathMatch: 'full'},
    {path: 'login', component: LoginComponent, canActivate: [ExternalAuthguardService]},
    {path: 'privacy', component: PrivacyComponent},
    {path: 'forgot-password', component: ForgotPasswordComponent},
    {path: '', loadChildren: () => import('./internal/internal.module').then(m => m.InternalModule)}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
