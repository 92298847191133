import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {filter} from 'rxjs/operators';
import {Router} from '@angular/router';
import {PlatformLocation} from '@angular/common';
import {Location} from '@angular/common';
import {ApiService} from './services/api.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    message;
    subscription: Subscription;
    loading: false;

    // showModal = false;

    constructor(private api: ApiService, private swUpdate: SwUpdate,
                private zone: NgZone, location: PlatformLocation, public router: Router,
                private _location: Location) {
        location.onPopState(() => {
            this.zone.run(() => {
                const url = this.getUrl();
                this.api.navigate(url, '');
                // this.router.navigate([url]);
            });
        });
        this.subscription = this.api.loaderStatus.subscribe(status => {
            this.loading = status;
        });
    }

    ngOnInit() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                if (confirm('New version available. Load New Version?')) {
                    window.location.reload();
                }
            });
        }
    }

    getUrl() {
        const len = this._location.path().indexOf(';');
        let str = this._location.path();
        if (len !== -1) {
            str = (this._location.path().slice(0, (len)));
        }
        return str;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
