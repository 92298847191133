import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {

    public form: FormGroup;
    type1 = 'text';
    type2 = 'text';
    inValidLink;

    constructor(
        public api: ApiService,
        private formBuilder: FormBuilder,
        private activeRoute: ActivatedRoute
    ) {
        this.form = this.formBuilder.group({
            password: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(15)])],
            confirmPassword: ['', Validators.required],
        });
    }

    ngOnInit() {
        this.verifyUrl();
    }

    changeType(num) {
        if (num === 1) {
            if (this.type1 === 'text') {
                this.type1 = 'password';
            } else {
                this.type1 = 'text';
            }
        } else {
            if (this.type2 === 'text') {
                this.type2 = 'password';
            } else {
                this.type2 = 'text';
            }
        }
    }

    verifyUrl() {
        const expireAt = this.activeRoute.snapshot.queryParams['expireAt'];
        const currTime = new Date().getTime();
        if (expireAt < currTime) {
            this.inValidLink = 'Your link has been expired.';
        }
    }

    forgotPasswordFun() {
        if (this.form.valid) {
            if (this.form.value.password !== this.form.value.confirmPassword) {
                this.api.toastr.error('Confirm password should same with new password');
                return;
            }
            const obj: any = {
                _id: this.activeRoute.snapshot.queryParams['key'],
                newPassword: this.form.value.password,
                flag: 1,
                timeStamp: new Date().getTime()
            };
            this.api.postData(this.api.apiUrl.changePassword, obj, true)
                .subscribe(res => {
                        this.api.toastr.success('Password Changed Successfully');
                    },
                    () => {
                    });
        } else {
            this.api.setTouched(this.form);
        }
    }

}
