import {Component, Input, Output, OnChanges, EventEmitter, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-my-instructions',
    templateUrl: './myInstructions.component.html'
})
export class MyInstructionsComponent implements OnChanges {

    @ViewChild(ModalDirective, { static: false }) modal: ModalDirective;

    @Input() isOpen: boolean;

    @Input() msg: string;

    @Output() finalSubmit: EventEmitter<any> = new EventEmitter();

    constructor() {

    }

    instructions: any;

    ngOnChanges() {
        if (this.isOpen) {
            this.modal.show();
        } else {
            if (this.modal) {
                this.modal.hide();
            }
        }

        this.instructions = JSON.parse(localStorage.getItem('savedQuestions'));

    }

    confirm(): void {
        const obj: any = {};
        obj.confirm = true;
        obj.modal = false;
        this.finalSubmit.emit(obj);
    }

    decline(): void {
        const obj: any = {};
        obj.confirm = false;
        obj.modal = false;
        this.finalSubmit.emit(obj);
    }

    handler() {
        if (this.isOpen) {
            this.decline();
        }
    }

}
