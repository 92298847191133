import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

    public form: FormGroup;
    public loader = false;
    rememberMeControl = new FormControl(false);

    constructor(
        private formBuilder: FormBuilder,
        public api: ApiService,
        public activeRoute: ActivatedRoute
    ) {
        this.form = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.pattern(this.api.constant.EMAIL_REGEX)])],
            password: ['', Validators.required]
        });
    }

    ngOnInit() {
        if (localStorage.getItem('rememberMe')) {
            this.rememberMeControl.patchValue(true);
            this.form.patchValue(JSON.parse(localStorage.getItem('rememberData')));
        }
    }

    loginFun() {
        if (this.form.valid) {
            this.loader = true;
            this.api.postDataEnc(this.api.apiUrl.login, this.form.value, '', false)
                .subscribe(res => {
                        res = JSON.parse(this.api.decrypt(res.message, ''))
                        if (res.statuscode === 400) {
                            this.api.toastr.error(res.msg);
                            this.loader = false;
                            return;
                        }
                        if (this.rememberMeControl.value) {
                            localStorage.setItem('rememberMe', this.rememberMeControl.value);
                            localStorage.setItem('rememberData', JSON.stringify(this.form.value));
                        } else {
                            localStorage.removeItem('rememberMe');
                            localStorage.removeItem('rememberData');
                        }
                        if(res.data.items.length > 0){
                            localStorage.setItem('mindHelpToken', res.data.items[0].token);
                            localStorage.setItem('ppk', res.data.items[0].encKey);
                        }
                        else{
                            localStorage.setItem('mindHelpToken', '');
                            localStorage.setItem('ppk', '');
                        }
                        this.api.navigate('dashboard', '');

                    },
                    (e) => {
                        this.loader = false;
                        this.api.toastr.error("Existe un problema con la conexión, intente de nuevo.");
                        //var err = this.api.decrypt(e.error.message,'');
                        
                    });
        } else {
            this.api.setTouched(this.form);
        }
    }

}


