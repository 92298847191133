import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthGuardService as AuthGuard} from './services/authguard.service';
import {AppComponent} from './app.component';
import {LoginComponent} from './external/login/login.component';
import {ExportToExcelService} from './services/exportToExcel.service';
import {SharedModule} from './shared/modules/shared.module';
import {CapitalizePipe} from './shared/pipes/capitalizefirst.pipe';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from './services/api.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {InterceptorService} from './services/interceptor.service';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {LightboxModule} from 'ngx-lightbox';
import {AsyncPipe} from '@angular/common';
import {ClickOutsideModule} from 'ng-click-outside';
import {PrivacyComponent} from './external/privacy/privacy.component';
import {ExternalAuthguardService} from './services/externalAuthguard.service';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {ModalModule} from 'ngx-bootstrap';
import {ForgotPasswordComponent} from './external/forgot-password/forgot-password.component';
import {MyInstructionsComponent} from './shared/modals/myInstructions/myInstructions.component';

@NgModule({
    declarations: [
        MyInstructionsComponent,
        CapitalizePipe,
        AppComponent,
        LoginComponent,
        PrivacyComponent,
        ForgotPasswordComponent
    ],
    imports: [
        SharedModule,
        BrowserModule,
        ClickOutsideModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {
            enabled: environment.production
        }),
        NgbModule,
        CommonModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            closeButton: true,
            timeOut: 3000,
            progressAnimation: 'increasing',
            preventDuplicates: true,
            resetTimeoutOnDuplicate: true,
            progressBar: true
        }),
        LazyLoadImageModule,
        LightboxModule,
        NgMultiSelectDropDownModule.forRoot(),
        ModalModule.forRoot()
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        },
        ApiService,
        AuthGuard,
        AsyncPipe,
        ExternalAuthguardService,
        ExportToExcelService
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
