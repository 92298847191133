export const environment = {
    production: false,
    // apiBaseUrl: 'https://mindhelp.mx/api/v1/' //live
    // apiBaseUrl: 'https://test.mindhelp.mx/api/v1/' //test
    // apiBaseUrl: 'http://192.168.100.142:7002/api/v1/' //local
    //apiBaseUrl: 'https://mindhelp.mx/api/v1/'
    // apiBaseUrl: 'https://test.mindhelp.mx/api/v1/' //test
    //apiBaseUrl: 'https://api.mindhelp.dev/api/v1/' //dev
    apiBaseUrl: 'https://api.mindhelp.dev/api/v1/' //Prod
    //apiBaseUrl: 'http://192.168.100.7:1337/api/v1/' //Local
};