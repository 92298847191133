import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GoogleplaceDirective} from '../directives/googlePlaces.directive';
import {BsDatepickerModule} from 'ngx-bootstrap';
// export let options: Partial<IConfig> | (() => Partial<IConfig>);

import {
    ModalModule, TabsModule, PaginationModule, BsDropdownModule,
    CollapseModule, AccordionModule
} from 'ngx-bootstrap';


@NgModule({
    imports: [
        BsDatepickerModule.forRoot(),
        CommonModule,
        PaginationModule.forRoot(),
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        CollapseModule.forRoot(),
        AccordionModule.forRoot()
    ],
    declarations: [GoogleplaceDirective],
    exports: [
        BsDatepickerModule,
        GoogleplaceDirective,
        PaginationModule,
        BsDropdownModule,
        TabsModule,
        ModalModule,
        CollapseModule,
        AccordionModule
    ]
})
export class SharedModule {
}
